<template>
  <base-view>
    <view-section>
      <div v-if="!section">
        <p>Loading...</p>
      </div>
      <div v-else>
        <h1 class="mb-8 text-4xl font-semibold">{{ section.name }}</h1>
        <div class="overflow-hidden rounded-lg shadow-lg">
          <h2 class="p-4 m-0 text-white bg-primary">All Articles</h2>
          <a
            v-for="article in section.articles"
            :href="`/support/articles/${article.id}`"
            :key="article.id"
            class="block p-4 border-0 border-b border-neutral border-solid last:border-b-0 hover:bg-neutral-light active:bg-neutral"
          >
            {{ article.name }}
          </a>
        </div>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

export default {
  name: "Section",
  components: {
    BaseView,
    ViewSection
  },
  data() {
    return {
      sectionId: parseInt(this.$route.params.sectionId)
    };
  },
  computed: {
    section() {
      return this.$store.getters.sectionById(this.sectionId);
    }
  }
};
</script>
